<template>
	<div class="-edit-main -ml50">
		
		<el-form class="-form -full -w500 -mt50" :model="form" :rules="rules" ref="form" @submit.native="submit()" @submit.native.prevent>
			<el-form-item label="当前密码" prop="upwd">
				<el-input type="password" v-model="form.upwd" clearable></el-input>
			</el-form-item>
			<el-form-item label="新密码" prop="nupwd">
				<el-input type="password" v-model="form.nupwd" clearable ></el-input>
			</el-form-item>
			<el-form-item label="确认密码" prop="qnupwd">
				<el-input type="password" v-model="form.qnupwd" clearable></el-input>
			</el-form-item>
			<div class="-ml100">
				<el-button native-type="submit" type="primary" class="-ml30 -mt30">提 交</el-button>
			</div>
		</el-form>
	
	</div>
</template>

<script>
export default {
	data() {
		return {
			view: {
				width: '130px'
			},
			form: {},
			rules: {
				upwd: [
					{ required: true, message: '当前密码', trigger: 'blur' },
				],
				nupwd: [
					{ required: true, message: '新密码', trigger: 'blur' },
				],
				qnupwd: [
					{ required: true, message: '确认密码', trigger: 'blur' },
				],
			}
		}
	},
	methods: {
		
		//提交表单
		submit() {
			this.$refs['form'].validate((valid) => {
				if (valid) {
					if(this.form.nupwd != this.form.qnupwd) {
						return this._msg('新密码与确认密码输入不一致')
					}
					this._post('/System/User/modifyUpwd', this.form, (res)=>{
						this._msg('密码修改成功')
						this.$refs['form'].resetFields()
					})
				} else return false
			})
			return false
		}
		
	}
}
</script>
